import * as React from 'react'

import * as styles from '@/components/Luxury/Main/Main.module.scss'

const LuxuryMain = () => {
  return (
    <section className={styles['main']}>
      <div className={styles['background']}>
        <div className={styles['container']}>
          <h2 className={styles['title']}>
            シェフ・イン・レジデンス
            <br />
            <span className={`${styles['font']} ${styles['subtitle']}`}>
              IN SABAE
            </span>
          </h2>
          <p className={styles['description']}>
            伝統工芸品を愛でながら、味わう料理。
            <br />
            越前焼のお皿や漆器を使ってコース料理を提供してみませんか？
            <br />
            <div className={styles['adjust']}>
              「美味しい食材」×「福井の伝統工芸」であなたの料理をより美しく、より美味しく。
            </div>
          </p>
        </div>
      </div>
    </section>
  )
}

export default LuxuryMain
