import * as React from 'react'

import type { HeadFC, PageProps } from 'gatsby'

import SEO from '@/components/Base/SEO'
import Layout from '@/components/Layout'
import LuxuryEntry from '@/components/Luxury/Entry'
import LuxuryImages from '@/components/Luxury/Images'
import LuxuryImagesleft from '@/components/Luxury/Images-left'
import LuxuryMain from '@/components/Luxury/Main'
import LuxurySlider from '@/components/Luxury/Slide'
import LuxuryThanks from '@/components/Luxury/Thanks'
import LuxuryVideo from '@/components/Luxury/Video'
import PageTitle from '@/components/Parts/PageTitle'

type PageContext = {
  breadcrumb: {
    crumbs: {
      pathname: string
      crumbLabel: string
    }[]
  }
}

const LuxuryPage = ({
  pageContext: {
    breadcrumb: { crumbs },
  },
}: PageProps<Queries.SitePage, PageContext>) => {
  return (
    <Layout logo={true} dark={true}>
      <main>
        <PageTitle
          title='一流を愛でる・味わう'
          page='luxury'
          curtain={false}
          crumbs={crumbs}
          crumbLabel='Luxury kitchen'
        />
        <LuxuryMain />
        <LuxuryVideo />
        <LuxuryImages desc1='乾杯はワイングラスで' desc2='' src={1} />
        <LuxuryImagesleft desc1='個性的なBlueの越前漆器' desc2='' src={2} />
        <LuxuryImages desc1='前菜にはオリジナルのタモトレー' desc2='' src={3} />
        <LuxuryImagesleft
          desc1='個性的なデザインの越前焼の器'
          desc2=''
          src={4}
        />
        <LuxuryImages
          desc1='土と金属感が食材を彩ります'
          desc2='古越前の伝統、'
          src={5}
        />
        <LuxuryImagesleft
          desc1='塗り肌が美しい越前漆器のお椀'
          desc2='上品で艶やかな'
          src={6}
        />
        <LuxuryImages
          desc1='越前打ち刃物のステーキナイフ'
          desc2='抜群の切れ味を誇る'
          src={7}
        />
        <LuxuryThanks />
        <LuxurySlider />
        <LuxuryEntry />
      </main>
    </Layout>
  )
}

export default LuxuryPage

export const Head: HeadFC = ({ location }) => (
  <SEO
    title='一流を愛でる・味わう'
    pathname={location.pathname}
    page='luxury'
  />
)
